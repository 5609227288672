import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default () => {
  return (
    <div>
      404
    </div>
  );
};
