import React, {useState} from 'react';
import ForgotPassword from "./forgot_password/ForgotPassword";
import Password from "./Password";

const PasswordTab = () => {

  const [isForgotPassword, setIsForgotPassword] = useState(false);

  return (
    <>
      {isForgotPassword ? (
        <ForgotPassword setIsForgotPassword={setIsForgotPassword} />
      ) : (
        <Password setIsForgotPassword={setIsForgotPassword} />
      )}
    </>
  );
};

export default PasswordTab;