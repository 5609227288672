import React, { useEffect, useState } from "react";
import "../default_components/RecipeComponent/recipe_component.scss";
import Api from "../../Api";
import favorite_colored from "./images/favorite_colored.svg";
import favorite_outlined from "./images/favorite_outlined.svg";

const SimilarRecipies = ({ request, color, recipeId }) => {
  const [loadmore, setLoadmore] = useState(false);
  const [likes, setLikes] = useState([]);
  const [meals, setMeals] = useState([]);

  useEffect(() => {
    console.log(meals);

    Api.Likes.getLikes(meals.map((meal) => meal.id)).then((response) => {
      setLikes(response.data);
    });
  }, [meals]);

  const addToFavorite = (mealId) => {
    Api.Likes.postLikes(mealId).then((response) => {
      console.log(response);

      Api.Likes.getLikes(meals.map((meal) => meal.id)).then((response) => {
        setLikes(response.data);
      });
    });
  };

  const removeFromFavorite = (mealId) => {
    Api.Likes.deleteLikes(mealId).then((response) => {
      console.log(response);

      Api.Likes.getLikes(meals.map((meal) => meal.id)).then((response) => {
        setLikes(response.data);
      });
    });
  };

  const mealIsFavorite = (mealId) => {
    return likes.filter((like) => like.meal_id === mealId)[0] !== undefined;
  };

  const loadMore = () => {
    setLoadmore(true);
  };

  useEffect(() => {
    request(setMeals, recipeId);
  }, 1);

  const getMealName = (meal) => {
    if (meal.name.length > 25) {
      return `${meal.name.slice(0, 25)}...`;
    } else {
      return meal.name;
    }
  };

  if (meals.length === 0) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="recipe-boxes random">
      {meals.slice(0, 5).map((meal) => (
        <div className="recipe-box">
          {mealIsFavorite(meal.id) ? (
            <img
              className="add-like-button"
              onClick={(e) => {
                removeFromFavorite(meal.id);
              }}
              src={favorite_colored}
              alt=""
            />
          ) : (
            <img
              className="remove-like-button"
              onClick={(e) => {
                addToFavorite(meal.id);
              }}
              src={favorite_outlined}
              alt=""
            />
          )}
          <div
            className="recipe-box-link"
            onClick={() => window.location.replace(`/recipe/${meal.id}`)}
          >
            <div>
              <img
                size={"65%"}
                className="meal-image"
                src={meal.image_url}
                onClick={() => window.location.replace(`/recipe/${meal.id}`)}
                style={{ cursor: "pointer" }}
              />
              <p
                className="meal-title"
                color={color}
                onClick={() => window.location.replace(`/recipe/${meal.id}`)}
              >
                {getMealName(meal)}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SimilarRecipies;
