import React, { useState } from "react";
import profile_avatar from "./images/profile_avatar.svg";
import logo from "./images/logo.svg";
import burger_menu from "./images/burger_menu.svg";
import "./header.scss";
import HamburgerNavigation from "./HamburgerNavigation";
import Dropdown from "./Dropdown";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [avatarDropdownOpen, setAvatarDropdownOpen] = useState(false);
  const [avatarAnchor, setAvatarAnchor] = useState();

  const openDropdown = (e) => {
    setAvatarAnchor(e.currentTarget);
    setAvatarDropdownOpen(true);
  }

  return (
    <div className="header">
      <div className="header-container">
        <div className="header__logo-n-links">
          <div className="logo">
              <a href="/dashboard"  className="logo">
                <img src={logo} alt=""/>
              </a>
          </div>

          <div>
            <ul className="header__links">
              <li>
                <a className="header__link" href="/planner">
                  Meal planner
                </a>
              </li>
              <li>
                <a className="header__link" onClick={() => window.location.replace('/my-recipes')}>
                  My recipes
                </a>
              </li>
              <li>
                <a target="_blank" className="header__link" href="https://fryingplan.com/blog/">
                  Blog
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="header__search-n-profile">
          {/*<div className="header__search-bar">*/}
          {/*  <form className="header__search-form" action="/search">*/}
          {/*    <input*/}
          {/*      className="header__search-input"*/}
          {/*      type="text"*/}
          {/*      name="text"*/}
          {/*      placeholder="Type something"*/}
          {/*    />*/}
          {/*    <button className="header__search-button">*/}
          {/*      /!*<img src={search_icon} alt="" />*!/*/}
          {/*    </button>*/}
          {/*  </form>*/}
          {/*</div>*/}

          <div className="header__profile-n-burgernav">
            <div className="header__profile" onClick={openDropdown}>
              <img src={profile_avatar} alt="" />
            </div>

            <img
              className="header__burgernav"
              src={burger_menu}
              alt=""
              onClick={() => {
                setOpenMenu(true);
              }}
            />
          </div>
        </div>
      </div>

      <Dropdown anchorEl={avatarAnchor} dropdownOpen={avatarDropdownOpen} setDropdownOpen={setAvatarDropdownOpen} />

      <HamburgerNavigation openMenu={openMenu} setOpenMenu={setOpenMenu} />
    </div>
  );
};

export default Header;
