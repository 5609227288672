import React, {useEffect, useRef, useState} from "react";
import AuthComponent from "./AuthComponent";
import {Link, Navigate, Redirect} from "react-router-dom";
import Api from "../../Api";
import "./register.scss"
import "./images/ok_input.svg"
import "./images/vision_icon.svg"
import "./images/no_vision_icon.svg"
import "./images/fp_orange.svg"
import ProfileInput from "../profile/default_components/ProfileInput";
import backgroundImg from "./images/bg_reg_pages.jpg";
import {useMethodsSnackbar} from "../default_components/snackbar/useSnackBarMethods";
import DefaultSnackbar from "../default_components/snackbar/SnackBar";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nameDirty, setNameDirty] = useState(false)
  const [emailDirty, setEmailDirty] = useState(false)
  const [passwordDirty, setPasswordDirty] = useState(false)
  const [nameError, setNameError] = useState("This field cannot be empty")
  const [emailError, setEmailError] = useState("This field cannot be empty")
  // const [passwordError, setPasswordError] = useState("This field cannot be empty")
  // const [passwordErrorLater, setPasswordErrorLater] = useState("")
  // const [passwordErrorNumber, setPasswordErrorNumber] = useState("")

  const [checkPasswordLength, setCheckPasswordLength] = useState();
  const [checkPasswordHaveNumbers, setCheckPasswordHaveNumbers] = useState();
  const [checkPasswordHaveLetters, setCheckPasswordHaveLetters] = useState();
  const passwordValidationRef = useRef(null);

  const [passwordVision, setPasswordVision] = useState(false)
  const [showPasswordValidations, setShowPasswordValidations] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [visionIconErrorName, setVisionIconErrorName] = useState(false)
  const [visionIconErrorEmail, setVisionIconErrorEmail] = useState(false)
  const [visionIconErrorPassLength, setVisionIconErrorPassLength] = useState(false)
  const [visionIconErrorPassIncludesLater, setVisionIconErrorPassIncludesLater] = useState(false)
  const [visionIconErrorPassIncludesNumber, setVisionIconErrorPassIncludesNumber] = useState(false)
  const [showWindowCheckEmail, setShowWindowCheckEmail] = useState(false)

  const { open, message, isSuccessful, close, getActiveSnackbar } = useMethodsSnackbar();
  
  useEffect(() => {
    if (nameError || emailError || passwordDirty) {
      setFormValid(false)
    } else {
      setFormValid(true)
    }
  }, [nameDirty, emailDirty, passwordDirty])

  useEffect(() => {
    checkPasswordHaveNumbers && checkPasswordHaveLetters && checkPasswordLength
        ? setPasswordDirty(false)
        : setPasswordDirty(true)
  }, [checkPasswordHaveNumbers, checkPasswordHaveLetters, checkPasswordLength])
  
  const nameHandler = (e) => {
    setName(e.target.value)

    if(!/^[a-zA-Z ]{3,}$/.test(e.target.value) || !name.replace(/\s/g, '').length) {
      setNameError("Incorrect Full Name")
      setNameDirty(true)
    }
    else {
      setNameError("")
      setNameDirty(false)
    }

    if(!e.target.value) {
      setNameError("This field cannot be empty")
      setNameDirty(true)
    }
  }
  
  const emailHandler = (e) => {
    setEmail(e.target.value)
    if(!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(e.target.value)) {
      setEmailError("Incorrect email")
      setEmailDirty(true)
    }
    else {
      setEmailError("")
      setEmailDirty(false)
    }
    if(!e.target.value) {
      setEmailError("This field cannot be empty")
      setEmailDirty(true)
    }
  }
  
  const passwordHandler = (e) => {
    setPassword(e.target.value);

    const str = /[0-9a-zA-Z!@#$%^&*]{8,}/;
    if (!str.test(String(e.target.value))) {
      setCheckPasswordLength(false);
    }
    else {
      setCheckPasswordLength(true);
    }

    const str1 = /[0-9]/;
    if (!str1.test(String(e.target.value))) {
      setCheckPasswordHaveNumbers(false);
    }
    else {
      setCheckPasswordHaveNumbers(true);
    }

    const str2 = /[a-zA-Z]/;
    if (!str2.test(String(e.target.value))) {
      setCheckPasswordHaveLetters(false);
    }
    else {
      setCheckPasswordHaveLetters(true);
    }

    if (checkPasswordHaveLetters && checkPasswordHaveNumbers && checkPasswordLength) {
      setPasswordDirty(false)
    } else {
      setPasswordDirty(true)
    }
  }
  
  const handlerVisionPass = () => {
    setPasswordVision(!passwordVision)
  }
  
  const blurHandler = (e) => {
    switch (e.target.name) {
      case "name":
        setNameDirty(true)
        break
      case "email":
        setEmailDirty(true)
        break
      case "password":
        setPasswordDirty(true)
        break
    }
  }
  const CheckEmailWindow = () => {
    setShowWindowCheckEmail(true)
  }
  const CheckEmailWindow_none = () => {
    setShowWindowCheckEmail(false)
  }
  console.log(formValid)
  
  const [navigate, setNavigate] = useState(false);

  const RegisterClick = () => {
    Api.Auth.register(name, email, password)
      .then((response) => {
        getActiveSnackbar('Registration successful', true);
        CheckEmailWindow();
        setTimeout(() => {
          setNavigate(true);
            }, 2000);
      })
        .catch((error) => {
          getActiveSnackbar('This email already exist', false);
        })
  }
  
  if (navigate) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="back-reg-page__box">
      <div className='background__image' />
      {showWindowCheckEmail?
        <div className="CheckEmailWindow">
          <div className="FrPlan__orange"/>
          <div className="CheckEmailWindow-box-text">
            <p className="CheckEmailWindow-box-text1">Check youre Email</p>
            <p className="CheckEmailWindow-box-text2">You successfully registrated on FryingPlan!</p>
            <p className="CheckEmailWindow-box-text3">Please confirm your Email in letter we sent to <p className="CheckEmailWindow-box-text3__p">email_adress@mail.com</p> </p>
            <div className="OrangeIMG"></div>
            <div className="registerButton">
              <div onClick={CheckEmailWindow_none}>Okay!</div>
            </div>
          </div>
        </div>
        :
        <div className="back-reg-page__box-content">
          <div className="FrPlan__white"/>
          <div className="RegisterComponent">
            <div className="JoinHeader">Sign up</div>
            <div className="field-input">
              <p className="field-input__p"
                 style={nameDirty && nameError ? {color: "red"} : {color: "#4E598C"}}
              >
                Full Name
              </p>
              <input
                style={nameDirty && nameError ? {border: "1px solid red"} : {border: "1px solid #A3A8C3"}}
                onChange={e => {
                  nameHandler(e);
                }}
                onBlur={e => {blurHandler(e)}}
                className="field-input__box"
                name="name"
                type="text"
                value={name}
                placeholder="John Smith"
              />
              {(nameDirty && nameError) && <div style={{color: "red"}}>{nameError}</div>}
              {/*<input*/}
              {/*  onChange={e => nameHandler(e)}*/}
              {/*  onBlur={e => {blurHandler(e)}}*/}
              {/*  className="field-input__box"*/}
              {/*  name="name"*/}
              {/*  type="text"*/}
              {/*  value={name}*/}
              {/*  placeholder="John Smith"/>*/}
            </div>
            {/*{(nameDirty && nameError) &&*/}
            {/*  <div*/}
            {/*    style={!visionIconErrorName ?*/}
            {/*      {display: "flex", alignItems: "center", color: "red"}*/}
            {/*      :*/}
            {/*      {display: "flex", alignItems: "center", color: "green"}}*/}
            {/*  >*/}
            {/*    {visionIconErrorName?*/}
            {/*      <div className="visionIconOk"/>*/}
            {/*      :*/}
            {/*      <div className="visionIconError"/>*/}
            {/*    }*/}
            {/*    {nameError}*/}
            {/*  </div>*/}
            {/*}*/}
            <div className="field-input">
              <p className="field-input__p"
                 style={emailDirty && emailError ? {color: "red"} : {color: "#4E598C"}}
              >
                Email
              </p>
              <input
                onChange={e => {
                  emailHandler(e);
                }}
                onBlur={e => blurHandler(e)}
                className="field-input__box"
                name="email"
                type="text"
                value={email}
                placeholder="john_smith@mail.com"
                style={emailDirty && emailError ? {border: "1px solid red"} : {border: "1px solid #A3A8C3"}}
              />
              {(emailDirty && emailError) && <div style={{color: "red"}}>{emailError}</div>}
            </div>
            {/*{(emailDirty && emailError) &&*/}
            {/*  <div*/}
            {/*    style={!visionIconErrorEmail ?*/}
            {/*      {display: "flex", alignItems: "center", color: "red"}*/}
            {/*      :*/}
            {/*      {display: "flex", alignItems: "center", color: "green"}}*/}
            {/*  >*/}
            {/*    {visionIconErrorEmail?*/}
            {/*      <div className="visionIconOk"/>*/}
            {/*      :*/}
            {/*      <div className="visionIconError"/>*/}
            {/*    }*/}
            {/*    {emailError}*/}
            {/*  </div>*/}
            {/*}*/}
            <div className="field-input">
              <p
                className="field-input__p"
                // style={passwordDirty ? {color: "red"} : {color: "#4E598C"}}
              >
                Password
              </p>
              <input
                onChange={e => {
                  passwordHandler(e);
                }}
                className="field-input__box"
                name="password"
                type={passwordVision? "text" : "password"}
                value={password}
                placeholder=""
                // style={passwordDirty ? {border: "1px solid red"} : {border: "1px solid #A3A8C3"}}
                onFocus={(e) => {setShowPasswordValidations(true)}}
              />
              {/*{(passwordDirty && passwordError) && <div style={{color: "red"}}>{passwordError}</div>}*/}
              <div onClick={handlerVisionPass} className={passwordVision? "VisionPasswordButton" : "NoVisionPasswordButton"}></div>
            </div>
            <ul ref={passwordValidationRef} className='password-validation__block' style={showPasswordValidations ? {height: passwordValidationRef.current?.scrollHeight} : {height: 0}}>
              <div className='password-validation__list'>
                <li style={checkPasswordLength === undefined ? {color: 'black'} : checkPasswordLength ? {color: 'green'} : {color: 'red'}}>minimum 8 characters</li>
                <li style={checkPasswordHaveNumbers === undefined ? {color: 'black'} : checkPasswordHaveNumbers ? {color: 'green'} : {color: 'red'}}>includes numbers</li>
                <li style={checkPasswordHaveLetters === undefined ? {color: 'black'} : checkPasswordHaveLetters ? {color: 'green'} : {color: 'red'}}>includes letters</li>
              </div>
            </ul>
            {/*{(passwordDirty && passwordError) &&*/}
            {/*  <div*/}
            {/*    style={!visionIconErrorPassLength ?*/}
            {/*      {display: "flex", alignItems: "center", color: "red"}*/}
            {/*      :*/}
            {/*      {display: "flex", alignItems: "center", color: "green"}}*/}
            {/*  >*/}
            {/*    {visionIconErrorPassLength?*/}
            {/*      <div className="visionIconOk"/>*/}
            {/*      :*/}
            {/*      <div className="visionIconError"/>*/}
            {/*    }*/}
            {/*    {passwordError}*/}
            {/*  </div>*/}
            {/*}*/}
            {/*{(passwordDirty && passwordErrorLater) &&*/}
            {/*  <div*/}
            {/*    style={!visionIconErrorPassIncludesLater ?*/}
            {/*      {display: "flex", alignItems: "center", color: "red"}*/}
            {/*      :*/}
            {/*      {display: "flex", alignItems: "center", color: "green"}}*/}
            {/*  >*/}
            {/*    {visionIconErrorPassIncludesLater?*/}
            {/*      <div className="visionIconOk"/>*/}
            {/*      :*/}
            {/*      <div className="visionIconError"/>}*/}
            {/*    {passwordErrorLater}*/}
            {/*  </div>}*/}
            {/*{(passwordDirty && passwordErrorNumber) &&*/}
            {/*  <div*/}
            {/*    style={!visionIconErrorPassIncludesNumber ?*/}
            {/*      {display: "flex", alignItems: "center", color: "red"}*/}
            {/*      :*/}
            {/*      {display: "flex", alignItems: "center", color: "green"}}*/}
            {/*  >*/}
            {/*    {visionIconErrorPassIncludesNumber?*/}
            {/*      <div className="visionIconOk"/>*/}
            {/*      :*/}
            {/*      <div className="visionIconError"/>}*/}
            {/*    {passwordErrorNumber}*/}
            {/*  </div>}*/}
            <button disabled={!formValid} onClick={RegisterClick} className={"registerButton"}>
              <div>Sign up</div>
            </button>
            {/*<div className="signUpWitchGoogle">*/}
            {/*  <p>Sign up with Google</p>*/}
            {/*  <div className="iconGoogle"/>*/}
            {/*</div>*/}
            <div className="JoinNowTextLine">
              <p>Already have account?</p>
              <Link to="/login" className="register__input-side__login-link">
                Log in
              </Link>
            </div>
          </div>
        </div>
      }

      <DefaultSnackbar
          open={open}
          onClose={close}
          message={message}
          isSuccessful={isSuccessful}
      />
    </div>
  );
};

export default Register;
