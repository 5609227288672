import React from 'react';
import "./subscribe.scss";
import banana from "./images/banana.svg"
import orangeFruit from "./images/orange.svg"
import plantMain from "./images/plantMain.svg"

const Subscribe = () => {

	return (
		<div className="subscribe-container">
			<div className="small-subscribe-box">
				<p className="size">S</p>
				<p className="subscribe-description">some description some description some description some description some description some description some description some description </p>
				<p className="subscribe-price">4.99 $</p>
				<button className="get-started">GET STARTED</button>
				<p className="banana"><img src={banana} alt="/"/></p>
			</div>
			<div className="main-subscribe-box">
				<p className="main-size">M</p>
				<p className="main-subscribe-description">some description some description some description some description some description some description some description some description </p>
				<p className="main-subscribe-price">9.99 $</p>
				<button className="get-started">GET STARTED</button>
				<p className="plantMain"><img src={plantMain} alt="/"/></p>
			</div>
			<div className="small-subscribe-box">
				<p className="orangeFruit"><img src={orangeFruit} alt="/"/></p>
				<p className="size-l">L</p>
				<p className="subscribe-description">some description some description some description some description some description some description some description some description </p>
				<p className="subscribe-price">14.99 $</p>
				<button className="get-started">GET STARTED</button>
			</div>
		</div>
	);
};

export default Subscribe;