import React, {useEffect} from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./my_recipes.scss";
import { useState } from "react";
import "./images/liked_active.svg";
import "./images/created_active.svg";
import RecipesComponent from "../default_components/RecipeComponent/RecipesComponent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Api from "../../Api";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MyRecipes = () => {
  const [likedMealsOpen, setLikedMealsOpen] = useState(true);
  const [value, setValue] = useState(0);
  const [likedMeals, setLikedMeals] = useState([]);
  const [createdMeals, setCreatedMeals] = useState([]);

  const handleActiveLiked = () => {
    setLikedMealsOpen(true);
    setValue(0);
  };
  const handleActiveCreated = () => {
    setLikedMealsOpen(false);
    setValue(1);
  };

  useEffect(() => {
    Api.Meals.liked()
      .then((response) => {
        setLikedMeals(response.data);
      })
  }, [])

  const mealsAmount = () => {
    return (likedMealsOpen ? likedMeals.length : createdMeals.length)
    console.log()
  }

  return (
    <>
      <Header />
      <div className="recipes_cont">
        <div className="button_box">
          <div
            className={
              likedMealsOpen ? "button_liked__active" : "button_created__active"
            }
          >
            <div
              onClick={handleActiveLiked}
              className="Click-button_liked__active"
            />
            <div
              onClick={handleActiveCreated}
              className="Click-button_created__active"
            />
          </div>

          <p>{mealsAmount()} item{mealsAmount() > 1 || mealsAmount() === 0 ? "s" : ""}</p>
        </div>
        <div style={{maxWidth: '1300px', width: '100%'}} className={""}>
          <TabPanel value={value} index={0}>
            <RecipesComponent meals={likedMeals} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RecipesComponent meals={createdMeals} />
          </TabPanel>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyRecipes;
