import { useState } from "react";

export const useMethodsSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccessful, setIsSuccessful] = useState();

  return {
    open,
    message,
    isSuccessful,
    getActiveSnackbar: (message, successful) => {
      setMessage(message)
      setIsSuccessful(successful)
      setOpen(true)
    },
    closeSnackbar: () => setOpen(false)
  }
}
