import React from "react";
import "./footer.scss";
import instagram_icon from "./images/instagram_icon.svg";
import facebook_icon from "./images/facebook_icon.svg";
import pinterest_icon from "./images/pinterest-icon.svg";
import twitter_icon from "./images/twitter_icon.svg";
import email_arrow from "./images/email_arrow.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="media-box">
          <div className="logo-box"></div>
          <div className="social-box">
            <div>
              <a target="_blank" href="https://www.instagram.com/fryingplan/">
                <img src={instagram_icon} alt="instagram icon" />
              </a>
            </div>

            <div>
              <a target="_blank" href="https://twitter.com/FryingPlan">
                <img src={twitter_icon} alt="twitter icon" />
              </a>
            </div>

            <div>
              <a target="_blank" href="https://www.pinterest.com/FryingPlan/">
                <img src={pinterest_icon} alt="linkedin icon" />
              </a>
            </div>

            <div>
              <a target="_blank" href="https://www.facebook.com/FryingPlan">
                <img src={facebook_icon} alt="facebook icon" />
              </a>
            </div>
          </div>
        </div>

        <div className="links-box">
          <div className="links-column">
            <ul>
              <li className="footer-link">
                <p>Frying Plan</p>
              </li>
              <li>
                <a href="">Blog</a>
              </li>
              <li>
                <a href="">About</a>
              </li>
              <li>
                <a href="">Contact</a>
              </li>
            </ul>
          </div>

          <div className="links-column">
            <ul>
              <li>
                <p>Support</p>
              </li>
              <li>
                <a href="">Help</a>
              </li>
              <li>
                <a href="">Privacy Policy</a>
              </li>
              <li>
                <a href="">Terms</a>
              </li>
              <li>
                <a href="">Sitemap</a>
              </li>
            </ul>
          </div>

          <div className="links-column">
            <ul>
              <li>
                <p>Get in touch</p>
              </li>
              <li>
                <a href="">info@fplan.com</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="email-box">
          {
            !localStorage.getItem("FP-Auth-Token") && <>
              <img src={email_arrow} alt="" />

              <p>Don't miss daily recipes</p>

              <div className="email-input-area">
                <form className="email-form" action="">
                  <input
                    className="email-input"
                    type="text"
                    placeholder="Enter your e-mail"
                  />
                  <a className="email-send" href="">
                    Subscribe
                  </a>
                </form>
              </div>
            </>
          }
        </div>
      </div>
      <div className="copyright-line">Сopyright</div>
    </div>
  );
};

export default Footer;
