import React from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
  background: #f7941d !important;
  box-shadow: 0px 3px 10px rgba(247, 148, 29, 0.3) !important;
  border-radius: 40px !important;
  color: white !important;

  height: 38px;

  font-size: 0.9rem !important;
  text-transform: unset !important;
  padding: 20px !important;
`;

const FPOrangeButton = ({ text, onClick, style }) => {
  return (
    <StyledButton onClick={onClick} style={style}>
      {text}
    </StyledButton>
  );
};

export default FPOrangeButton;
