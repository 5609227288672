import React from "react";
import TextField from "@mui/material/TextField";

import styled from "styled-components";

const FormComponent = styled.div`
  margin: 15px 0 10px 0;
  width: ${(props) => props.width};

  .input-field {
    width: 100%;
  }
  
  .Mui-focused fieldset {
    border-color: #F8931F !important;
  }
`;

const FormText = styled.p`
  margin: 0 0 5px 0;
  text-align: left;
  font-size: 1.1rem;
`;

const FPInputField = ({
  fieldName,
  onChange,
  fieldValue,
  type,
  inputProps,
  width,
  rows,
  multiline,
}) => {
  return (
    <FormComponent width={width}>
      {fieldName && <FormText>{fieldName}</FormText>}
      <TextField
        className="text-field tb__bg__light-grey input-field"
        variant="outlined"
        name="accountName"
        type={type}
        onChange={(event) => onChange(event.target.value)}
        value={fieldValue}
        inputProps={inputProps}
        rows={rows}
        multiline={!!multiline}
      />
    </FormComponent>
  );
};

export default FPInputField;
