import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Slide from "@mui/material/Slide";
import logo_hamburger_menu from "./images/logo_hamburger_menu.svg";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const HamburgerNavigation = ({ openMenu, setOpenMenu }) => {

  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem("FP-Auth-Token")

    navigate(`/login`, {
      replace: true,
    });
  }

  return (
    <div style={{ backgroundColor: "#FCAF58" }}>
      <Dialog
        fullScreen
        open={openMenu}
        onClose={() => {
          setOpenMenu(false);
        }}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "#FCAF58",
            height: "50px",
            width: "100%",
            pb: "100px",
            pt: "30px",
          }}
          variant="dense"
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <img src={logo_hamburger_menu} alt="" />
            <IconButton
              onClick={() => {
                setOpenMenu(false);
              }}
              aria-label="close"
            >
              <CloseSharpIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List sx={{ backgroundColor: "#FCAF58", height: "100%" }}>
          <ListItem button>
            <ListItemText
              onClick={() => window.location.replace('/my-recipes')}
              primaryTypographyProps={{
                color: "black",
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontWeight: "400",
              }}
              primary="My recipes"
            ></ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemText
              onClick={() => window.location.replace('/planner')}
              primaryTypographyProps={{
                color: "black",
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontWeight: "400",
              }}
              primary="Meal planner"
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              onClick={() => window.location.replace('https://fryingplan.com/blog/')}
              primaryTypographyProps={{
                color: "black",
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontWeight: "400",
                pb: "50px",
              }}
              primary="Blog"
            />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              onClick={() => window.location.replace('/profile')}
              primaryTypographyProps={{
                color: "black",
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontWeight: "400",
                pt: "50px",
              }}
              primary="My profile"
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              onClick={() => logout()}
              primaryTypographyProps={{
                color: "black",
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontWeight: "400",
              }}
              primary="Log out"
            />
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
};

export default HamburgerNavigation;
