import React, { useEffect, useState } from "react";
import styled from "styled-components";

const AuthContainer = styled.div`
  height: fit-content;
`

const Socials = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`

export default ({
  content
}) => {
  return (
    <>
      <div className="row">
        <AuthContainer className="col-sm-6 col-md-4 offset-sm-3 offset-md-4">
          {
            content
          }
        </AuthContainer>
      </div>

      <Socials className="row">
        <div className="col-sm-12 col-md-4">
          FryingPlan
        </div>

        <div className="col-sm-12 col-md-4">
          Socials
        </div>
      </Socials>
    </>
  );
};
