import axios from "axios";

let API_ROOT;

switch (process.env.REACT_APP_ENV) {
  case "staging":
    API_ROOT = "https://app.fryingplan.com/api";
    break;
  default:
    API_ROOT = "http://localhost:3005/api";
    break;
}

axios.defaults.baseURL = API_ROOT;

// TODO: Use Authorization HTTP header

let paramToken;

function updateParamTokens(token) {
  paramToken = { params: { token } };
}

updateParamTokens(localStorage.getItem("FP-Auth-Token"));

const Meals = {
  random: (amount) => axios.get(`meals/random`, { params: { amount: amount } }),

  you_may_like: () => axios.get(`meals/you_may_like`, paramToken),

  popular: () => axios.get(`meals/popular`, paramToken),

  new_recipes: () => axios.get(`meals/new_recipes`, paramToken),
  
  search_by_name: (name) => axios.get(`meals/${name}`, paramToken),

  get: async (id) => await axios.get(`meals/${id}`, paramToken),

  similar: async (id) => await axios.get(`meals/${id}/similar`, paramToken),

  update: (id, params) =>
    axios.patch(`meals/${id}`, {
      ...params,
      token: localStorage.getItem("FP-Auth-Token"),
    }),

  create: (name, instructions, steps, area, person, hardness, duration, measures, ingredients) =>
    axios.post(`meals`, {
      name: name,
      instructions: instructions,
      steps: steps,
      area: area,
      person: person,
      hardness: hardness,
      duration: duration,
      measures: measures,
      ingredients: ingredients,
      token: localStorage.getItem("FP-Auth-Token"),
    }),

  liked: () =>
    axios.get('meals/liked', paramToken)
};

const Auth = {
  register: async (name, email, password) => {
    const res = await axios.post(`auth/register`, {
      name: name,
      email: email,
      password: password,
    });

    if (res.status === 201) {
      const token = res.data;
      localStorage.setItem("FP-Auth-Token", token);
      updateParamTokens(token);
    }

    return res;
  },

  login: async (value, password) =>
    axios.post(`auth/login`, {
      email: value,
      password: password,
    }).then((res) => {
      if (res.status === 201) {
        const token = res.data.token;
        localStorage.setItem("FP-Auth-Token", token);
        updateParamTokens(token);
      } else {
        console.log('неверные данные ' + res)
      }
    })
};

const Schedules = {
  get: (dates) =>
    axios.get(`schedules`, {
      params: {
        dates: dates,
        token: localStorage.getItem("FP-Auth-Token"),
      },
    }),
};

const Likes = {
  getLikes: async (meal_ids) => {
    const res = await axios.get(`likes`, {
      params: {
        meal_ids: meal_ids,
        token: localStorage.getItem("FP-Auth-Token"),
      },
    });

    return res;
  },

  postLikes: async (meal_id, token) => {
    const res = await axios.post(`likes`, {
      meal_id: meal_id,
      token: localStorage.getItem("FP-Auth-Token"),
    });

    return res;
  },

  deleteLikes: async (meal_id) => {
    const res = await axios.delete(`likes/`, {
      params: {
        meal_id: meal_id,
        token: localStorage.getItem("FP-Auth-Token"),
      },
    });
    console.log(res);
    return res;
  },
};

const changeProfileInfo = async (name, first_week_day, measurement, allergens, restrictions) => {
  const resp = await axios.patch("/users", {
    name: name,
    first_week_day: first_week_day,
    measurement: measurement,
    allergens: allergens,
    restrictions: restrictions,
    token: localStorage.getItem("FP-Auth-Token"),
  });

  return resp;
};

const EmailSwitchCode = async (email) => {
  const resp = await axios.post("/users/email_switch_code", {
    email: email,
    token: localStorage.getItem("FP-Auth-Token"),
  });

  return resp;
}

const Password = async (old_password, new_password) => {
  const resp = await axios.patch("/users/password", {
    old_password: old_password,
    new_password: new_password,
    token: localStorage.getItem("FP-Auth-Token"),
  });

  return resp;
};

const NewEmail = async (new_email, code) => {
  const resp = await axios.patch("/users/email", {
    new_email: new_email,
    code: code,
    token: localStorage.getItem("FP-Auth-Token"),
  });

  return resp;
};

const userProfile = {
  getInfo: () => {
    const res = axios.get("/users/profile", {
      params: {
        token: localStorage.getItem("FP-Auth-Token"),
      },
    });

    return res;
  }
};

const userRole = {
  get: async () => {
    const res = await axios.get("/users/profile", {
      params: {
        token: localStorage.getItem("FP-Auth-Token"),
      },
    });

    return res;
  },
};

const Report = async (id, token) => {
  const res = axios.post('/meals/:id/report', {
    id: id,
    token: localStorage.getItem("FP-Auth-Token"),
  })
}

const getRecoveryLink = async (email) => {
  const res = await axios.post('/users/forget_password', {
    email: email,
    token: localStorage.getItem("FP-Auth-Token")
  })

  return res;
}

const resetPassword = async (password, password_token) => {
  const res = await axios.post('/users/change_password', {
    password: password,
    password_token: password_token,
    token: localStorage.getItem("FP-Auth-Token")
  })

  return res;
}

export default {
  userProfile,
  NewEmail,
  Password,
  changeProfileInfo,
  Report,
  Meals,
  Auth,
  Schedules,
  Likes,
  userRole,
  EmailSwitchCode,
  getRecoveryLink,
  resetPassword
};
