import React, {useEffect, useState} from 'react';
import ProfileInput from "../../default_components/ProfileInput";
import './ResetPassword.scss'
import backgroundImg from "../../../auth/images/bg_reg_pages.jpg";
import FPLogo from '../../../auth/images/fp_white.svg';
import Api from "../../../../Api";
import {useMethodsSnackbar} from "../../../default_components/snackbar/useSnackBarMethods";
import DefaultSnackbar from "../../../default_components/snackbar/SnackBar";
import {useLocation, useParams, useSearchParams} from "react-router-dom";

const ResetPassword = () => {

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordIsError, setNewPasswordIsError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [checkPasswordLength, setCheckPasswordLength] = useState();
  const [checkPasswordHaveNumbers, setCheckPasswordHaveNumbers] = useState();
  const [checkPasswordHaveLetters, setCheckPasswordHaveLetters] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { open, message, isSuccessful, close, getActiveSnackbar } = useMethodsSnackbar();

  const location = useLocation();

  useEffect(() => {
    if (newPassword === confirmPassword && newPassword.length > 0 && confirmPassword.length > 0) {
      setIsButtonDisabled(false);
    } else setIsButtonDisabled(true);
  }, [newPassword, confirmPassword]);

  const newPasswordHandler = (e) => {
    setNewPassword(e.target.value);

    const str = /[0-9a-zA-Z!@#$%^&*]{8,}/;
    if (!str.test(String(e.target.value))) {
      setCheckPasswordLength(false);
    }
    else setCheckPasswordLength(true);

    const str1 = /[0-9]/;
    if (!str1.test(String(e.target.value))) {
      setCheckPasswordHaveNumbers(false);
    }
    else setCheckPasswordHaveNumbers(true);

    const str2 = /[a-zA-Z]/;
    if (!str2.test(String(e.target.value))) {
      setCheckPasswordHaveLetters(false);
    }
    else setCheckPasswordHaveLetters(true);

    console.log(location.search)
  };

  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
    if (newPassword === e.target.value) {
      setConfirmPasswordError('');
    } else setConfirmPasswordError('Password mismatch');
  }

  const resetPassword = () => {
    const password_token = location.search.slice(16, )
    Api.resetPassword(newPassword, password_token).then(response => {
      if (response.data.status === 'ok') {
        getActiveSnackbar('Password was changed', true);
        setTimeout(() => {
          window.location.replace('/dashboard');
        }, 1500);
      } else {
        getActiveSnackbar('Error', true);
      }
    })
  }

  return (
    <div className='reset-pass__container'>
      <img className='reset-pass__FP-logo' src={FPLogo} alt={'FP Logo'} />
      <div className='reset-pass__background-image' />
      <div className='reset-pass__form'>
        <div className='reset-pass__form-title'>Reset Password</div>

        <ProfileInput
          label='New Password'
          type='password'
          showEye={true}
          onChange={e => newPasswordHandler(e)}
          value={newPassword}
          isError={newPasswordIsError}
        />

        <ul>
          <li style={checkPasswordLength === undefined ? {color: 'black'} : checkPasswordLength ? {color: 'green'} : {color: 'red'}}>minimum 8 characters</li>
          <li style={checkPasswordHaveNumbers === undefined ? {color: 'black'} : checkPasswordHaveNumbers ? {color: 'green'} : {color: 'red'}}>includes numbers</li>
          <li style={checkPasswordHaveLetters === undefined ? {color: 'black'} : checkPasswordHaveLetters ? {color: 'green'} : {color: 'red'}}>includes letters</li>
        </ul>

        <ProfileInput
          label='Confirm Password'
          type='password'
          showEye={true}
          onChange={e => confirmPasswordHandler(e)}
          value={confirmPassword}
          error={confirmPasswordError}
        />

        <button
          onClick={resetPassword}
          disabled={isButtonDisabled}
          className='reset-pass__form-btn'
        >
          <div>Reset Password</div>
        </button>

      </div>

      <DefaultSnackbar
        open={open}
        onClose={close}
        message={message}
        isSuccessful={isSuccessful}
      />
    </div>
  );
};

export default ResetPassword;