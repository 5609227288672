import React, { useEffect, useState } from "react";
import favorite_colored from "../../dashboard/images/favorite_colored.svg";
import favorite_outlined from "../../dashboard/images/favorite_outlined.svg";
import "./recipe_component.scss";
import Api from "../../../Api";

const RecipesComponent = ({ color, meals = [] }) => {
  const [likes, setLikes] = useState([]);
  const [listSize, setListSize] = useState(10);

  useEffect(() => {
    Api.Likes.getLikes(meals.map((meal) => meal.id)).then((response) => {
      setLikes(response.data);
    });
  }, [meals]);

  const addToFavorite = (mealId) => {
    Api.Likes.postLikes(mealId).then((response) => {
      Api.Likes.getLikes(meals.map((meal) => meal.id)).then((response) => {
        setLikes(response.data);
      });
    });
  };

  const removeFromFavorite = (mealId) => {
    Api.Likes.deleteLikes(mealId).then((response) => {
      console.log(response);

      Api.Likes.getLikes(meals.map((meal) => meal.id)).then((response) => {
        setLikes(response.data);
      });
    });
  };

  const mealIsFavorite = (mealId) => {
    return likes.filter((like) => like.meal_id === mealId)[0] !== undefined;
  };

  const loadMore = () => {
    setListSize(listSize + 10)
  };

  const getMealName = (meal) => {
    if (meal.name.length > 25) {
      return `${meal.name.slice(0, 25)}...`;
    } else {
      return meal.name;
    }
  };

  return (
    <div>
      <div className="recipe-boxes">
        {meals.slice(0, listSize).map((meal) => (
          <div className="recipe-box">
            {mealIsFavorite(meal.id) ? (
              <img
                className="add-like-button"
                onClick={(e) => {
                  removeFromFavorite(meal.id);
                }}
                src={favorite_colored}
                alt=""
              />
            ) : (
              <img
                className="remove-like-button"
                onClick={(e) => {
                  addToFavorite(meal.id);
                }}
                src={favorite_outlined}
                alt=""
              />
            )}
            <img
              src={meal.image_url}
              onClick={() => window.location.replace(`/recipe/${meal.id}`)}
              style={{ cursor: "pointer" }}
              className="meal-image"
            />
            <p
              className="meal-title"
              color={color}
              onClick={() => window.location.replace(`/recipe/${meal.id}`)}
            >
              {getMealName(meal)}
            </p>
          </div>
        ))}
      </div>
      {
        meals.length > listSize &&
          <p onClick={loadMore} className={"load-more-button"}>
            Load more...
          </p>
      }
    </div>
  );
};

export default RecipesComponent;
