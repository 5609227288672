import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const ButtonComponent = ({
  width,
  style,
  sx,
  variant,
  disabled,
  onClick,
  href,
  color,
  startIcon,
  endIcon,
  fieldName,
  disableElevation,
}) => {

  return (
    <Box width={width} style={style}>
      <Button
        disableElevation={disableElevation}
        sx={sx}
        variant={variant}
        disabled={disabled}
        onClick={onClick}
        href={href}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {fieldName}
      </Button>
    </Box>
  );
};

export default ButtonComponent;