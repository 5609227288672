import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Api from "../../Api";

const DayComponent = styled.div`
  border: 1px solid;
  display: flex;
`

const DayPartsComponent = styled.div`
  border: 1px solid red;
`

const DayPartComponent = styled.div`
  border: 1px solid blue;
`

const DayPartText = styled.div`

`

const MealName = styled.p`
  cursor: pointer;
`

const weekDayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const dayPart = ["breakfast", "lunch", "dinner"];

const DayBlock = ({weekDay}) => {
  const [meals, setMeals] = useState([]);
  const [openedInstructions, setOpenedInstructions] = useState(false);
  const [instructionsId, setInstructionsId] = useState(0);

  useEffect(() => {
    Api.Meals.random(3).then((response) => {
      setMeals(response.data);
    })
  }, [])

  const switchInstructions = (index) => {
    if (openedInstructions && instructionsId === index) {
      setOpenedInstructions(false);
    } else {
      setInstructionsId(index);
      setOpenedInstructions(true);
    }
  }

  return (
    <DayComponent className="col-md-6">
      <DayPartText className="col-md-4">
        <p>{weekDayNames[weekDay]}</p>
      </DayPartText>

      <DayPartsComponent  className="col-md-8">
        {
          meals.length > 0 && dayPart.map((dayPart, index) =>
            <DayPartComponent>
              <p>{dayPart}</p>

              <MealName onClick={() => switchInstructions(index)}>{meals[index].name}</MealName>

              { openedInstructions && instructionsId === index &&
                <p>{meals[index].instructions}</p>
              }
            </DayPartComponent>
          )
        }
      </DayPartsComponent>
    </DayComponent>
  )
}

export default DayBlock;