import React, {useState} from 'react';
import './ProfileInput.scss'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const ProfileInput = ({
  type: type,
  label: label,
  value: value,
  placeholder: placeholder,
  onChange: onChange,
  onBlur: onBlur,
  style: style,
  showEye: showEye,
  error: error,
  isError: isError,
  maxLength: maxLength,
  name: name
}) => {

  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  return (
    <div className='profile-input__box'>
      <label className='profile-input__label'>{label}</label>
      <input
        name={name}
        maxLength={maxLength}
        style={isError || error ? {...style,  maxWidth: maxLength, border: '2px solid red', transition: '0.5s'} : {...style, maxWidth: maxLength}}
        value={value}
        className='profile-input__input'
        type={type === 'password' ? (isVisiblePassword ? 'text' : 'password') : type}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      <p className='profile-input__error'>{error}</p>
      {showEye &&
        <div className='eye-container'>
          {isVisiblePassword
            ?
            <RemoveRedEyeOutlinedIcon onClick={() => setIsVisiblePassword(!isVisiblePassword)}/>
            :
            <VisibilityOffOutlinedIcon onClick={() => setIsVisiblePassword(!isVisiblePassword)}/>
          }
        </div>
      }
    </div>
  );
};

export default ProfileInput;