import React, { useState } from "react";
import WatchImage from "./images/timer.svg";
import PersonImage from "./images/persons.svg";
import FireImage from "./images/hardness_level.svg";
import "./meal_parameters.scss";

const MealParameters = ({ meal }) => {
  const calculatePersonString = () => {
    return meal.person > 1 ? "persons" : "person";
  };

  return (
    <div className="recipes__parameters">
      <div className="recipes__parameters-icon">
        <img src={PersonImage} />
        <p>
          {meal.person} {calculatePersonString()}
        </p>
      </div>

      <div className="recipes__parameters-icon">
        <img src={WatchImage} />
        <p>{meal.duration} mins</p>
      </div>

      <div className="recipes__parameters-icon">
        <img src={FireImage} />
        <p>{meal.hardness}</p>
      </div>
    </div>
  );
};

export default MealParameters;
