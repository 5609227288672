import React, { useEffect, useState } from "react";
import "../default_components/RecipeComponent/recipe_component.scss";
import DayBlock from "./DayBlock";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import RecipesComponent from "../default_components/RecipeComponent/RecipesComponent";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Api from "../../Api";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabsData(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default () => {
  const [value, setValue] = React.useState(0);
  const [tab_trand, setTabTrand] = useState(true);
  const [tab_new, setTabNew] = useState(false);
  const [youMayLikeRecipes, setYouMayLikeRecipes] = useState([]);

  useEffect(() => {
    Api.Meals.you_may_like().then((response) => {
      setYouMayLikeRecipes(response.data);
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeActiveTab = () => {
    setTabTrand(!tab_trand);
    setTabNew(tab_trand);
  };

  const dayBlocks = [];

  for (let i = 0; i < 7; i++) {
    dayBlocks.push(<DayBlock weekDay={i} />);
  }

  const getYouMayLikeRecipes = () => {

  }

  return (
    <>
      <Header />

      <div className="recipes-container">
        <Box
          className="tabs-box"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tabs
            className="tabs-change"
            value={value}
            onChange={handleChange}
            aria-label="just tabs"
            TabIndicatorProps={{
              hidden: "true",
            }}
          >
            <Tab
              onClick={changeActiveTab}
              className={tab_trand ? "tab-custom-active" : "tab-custom"}
              label="Trending recipes"
              {...tabsData(0)}
              style={{ textTransform: "none" }}
            />
            <Tab
              onClick={changeActiveTab}
              className={tab_new ? "tab-custom-active" : "tab-custom"}
              label="New Recipes"
              {...tabsData(1)}
              style={{ textTransform: "none" }}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <RecipesComponent meals={youMayLikeRecipes} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <RecipesComponent meals={youMayLikeRecipes} />
        </TabPanel>
      </div>

      <Footer />
    </>
  );
};
