import React, {useEffect, useState} from 'react';
import './PersonalInfo.scss'
import ProfileInput from "../default_components/ProfileInput";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import '../default_components/ProfileInput.scss'
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Api from "../../../Api";
import FryingButton from "../../default_components/CustomButtons/FryingButton";
import {allergensSelectData, dietSelectData} from "./data";

const PersonalInfoNew = () => {
  const [personAllergens, setPersonAllergens] = useState([]);
  const [personDiet, setPersonDiet] = useState([]);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState("")
  const [nameDirty, setNameDirty] = useState(false)
  const [weekDay, setWeekDay] = useState(null);
  const [measurement, setMeasurement] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    Api.userProfile.getInfo().then(response => {
      setName(response.data.name);
      console.log(response.data)

      const allergens = allergensSelectData.filter((item) => {
        return response.data.allergens.includes(item.id);
      })
      setPersonAllergens(allergens)

      const diet = dietSelectData.filter((item) => {
        return response.data.restrictions.includes(item.id);
      })
      setPersonDiet(diet);

      setWeekDay(response.data?.weekday)
      setMeasurement(response.data?.measurement)
    })
  }, []);

  useEffect(() => {
    if (nameError) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
  }, [nameError])

  const allergensChange = (event) => {
    const {value} = event.target
    const allergens = allergensSelectData.filter((item) => {
      return value.includes(item.name);
    })
    setPersonAllergens(allergens);
  };

  const dietChange = (event) => {
    const {value} = event.target
    const diet = dietSelectData.filter((item) => {
      return value.includes(item.name);
    })
    setPersonDiet(diet);
  };

  const handleDeleteAllergens = (chipToDelete) => () => {
    const newChips = personAllergens.filter((chip) => chip !== chipToDelete);
    setPersonAllergens(newChips);
  };

  const handleDeleteDiet = (chipToDelete) => () => {
    const newChips = personDiet.filter((chip) => chip !== chipToDelete);
    setPersonDiet(newChips);
  };

  const handleSubmit = () => {
    const allergens = [];
    personAllergens.map(value => allergens.push(value.id))
    const diet = [];
    personDiet.map(value => diet.push(value.id))
    Api.changeProfileInfo(name, weekDay, measurement, allergens, diet).then(res => {
      console.log(res.data)
      console.log(weekDay)
      console.log(measurement)
    })
  }

  const nameHandler = (e) => {
    setName(e.target.value)

    if(!/^[a-zA-Z ]{3,}$/.test(e.target.value)) {
      setNameError("Incorrect name")
      setNameDirty(true)
    }
    else {
      setNameError("")
      setNameDirty(false)
    }
    if(!e.target.value) {
      setNameError("This field cannot be empty")
      setNameDirty(true)
    }
  }

  return (
    <div className='profile-info__container'>
      <div className='profile-info__settings-container'>
        <div className='profile-info__photo-upload'>
          <Button className="upload-button" component="label">
            <p>Upload Photo</p>
            <p>*.png, *.jpg, *.jpeg</p>
            <input hidden accept="image/*" multiple type="file"/>
          </Button>
        </div>

        <div className='profile-info__first-clm-container'>
          <ProfileInput
            onChange={e => nameHandler(e)}
            value={name}
            label={'Name'}
            type={'text'}
            error={nameError}
          />

          <div className='profile-info__select-box'>
            <label className='profile-info__label'>Choose your allergens</label>
            <Select
              sx={{
                "& fieldset": { border: 'none' },
              }}
              multiple
              value={personAllergens.map(value => value.name)}
              onChange={(e) => allergensChange(e)}
              input={<OutlinedInput className='profile-info__select' label="Name" />}
            >
              {allergensSelectData.map((allergens) => (
                <MenuItem
                  key={allergens.id}
                  value={allergens.name}
                >
                  {allergens.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className='profile-info__select-box'>
            <label className='profile-info__label'>Choose your diet restrictions</label>
            <Select
              sx={{
                "& fieldset": { border: 'none' },
              }}
              multiple
              value={personDiet.map(value => value.name)}
              onChange={dietChange}
              input={<OutlinedInput className='profile-info__select' label="Name" />}
            >
              {dietSelectData.map((diets) => (
                <MenuItem
                  className='dshfkl'
                  key={diets.id}
                  value={diets.name}
                >
                  {diets.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <div className='profile-info__second-clm-container'>
          <Box
            sx={personAllergens.length > 7
              ? {marginBottom: '10px !important'}
              : {marginBottom: '40px !important'}
            }
            className='profile-info__chips-container'
          >
            {personAllergens.map((value) => (
              <Chip
                key={value.id}
                label={value.name}
                className='chip'
                onDelete={handleDeleteAllergens(value)}
              />
            ))}
          </Box>

          <Box className='profile-info__chips-container'>
            {personDiet.map((value) => (
              <Chip
                key={value.id}
                label={value.name}
                className='chip'
                onDelete={handleDeleteDiet(value)}
              />
            ))}
          </Box>
        </div>
      </div>

      <div className='profile-info__radio-container'>
        <div className='profile-info__select-box'>
          <label className='profile-info__label'>First day of the week:</label>
          <RadioGroup
            className='profile-info__radio-box'
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={(weekDay === null || undefined) ? "Monday" : weekDay}
            name="radio-buttons-group"
          >
            <FormControlLabel
              className='radio-button'
              value="Monday"
              control={<Radio/>}
              label="Monday"
              onClick={() => setWeekDay("Monday")}
            />

            <FormControlLabel
              className='radio-button'
              value="Sunday"
              control={<Radio/>}
              label="Sunday"
              onClick={() => setWeekDay('Sunday')}
            />
          </RadioGroup>
        </div>

        <div className='profile-info__select-box'>
          <label className='profile-info__label'>Measurement system:</label>
          <RadioGroup
            className='profile-info__radio-box'
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={(measurement === null || undefined) ? 'Metrical' : measurement}
            name="radio-buttons-group"
          >
            <FormControlLabel
              className='radio-button'
              value="Metrical"
              control={<Radio/>}
              label="Metrical"
              onClick={() => setMeasurement('Metrical')}
            />

            <FormControlLabel
              className='radio-button'
              value="Imperial"
              control={<Radio/>}
              label="Imperial"
              onClick={() => setMeasurement('Imperial')}
            />
          </RadioGroup>
        </div>
      </div>

      <div className="profile-info__btn-container">
        <FryingButton onClick={handleSubmit} label={'Save'} disabled={buttonDisabled} />
      </div>
    </div>
  );
};

export default PersonalInfoNew;