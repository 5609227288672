import React from "react";
import Header from "../header/Header";
import EditProfileNav from "./EditProfileNav";

const Profile = () => {


	return (
		<>
			<Header />
			<EditProfileNav />
		</>
	);
};

export default Profile;