import React, {useState} from 'react';
import Box from "@mui/material/Box"
import TabList from "@mui/lab/TabList"
import Tab from "@mui/material/Tab"
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext"
import "./edit_profile.scss";
import KeyIcon from '@mui/icons-material/Key';
import Email from "./Email/Email";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Subscribe from "./Subscribe/Subscribe";
import PersonalInfoNew from "./PersonalInfo/PersonalInfo";
import PasswordTab from "./Password/PasswordTab";

const EditProfileNav = () => {
	
	const [value, setValue] = React.useState('1');
	
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};
	
	return (
		<Box className="profile-nav" sx={{ width: '100%', typography: 'body1' }}>
			<TabContext value={value}>
				<Box sx={{display: "flex", justifyContent: "center"}}>
					<TabList className='profile-tabs-box' onChange={handleChange} aria-label="lab API tabs example">
						<Tab sx={{display: "inline-flex"}} icon={<PermIdentityIcon sx={{marginTop: "5px", marginRight: "10px"}}/>} className="profile-tab" label="personal data" value="1" />
						<Tab sx={{display: "inline-flex"}} icon={<EmailOutlinedIcon sx={{marginTop: "5px", marginRight: "10px"}}/>} className="profile-tab" label="email" value="2" />
						<Tab sx={{display: "inline-flex"}} icon={<KeyIcon sx={{marginTop: "5px", marginRight: "10px"}}/>} className="profile-tab" label="password" value="3" />
						<Tab sx={{display: "inline-flex"}} icon={<PaidOutlinedIcon sx={{marginTop: "5px", marginRight: "10px"}}/>} className="profile-tab" label="subscription" value="4" />
					</TabList>
				</Box>
				<TabPanel value="1"><PersonalInfoNew /></TabPanel>
				<TabPanel value="2"><Email /></TabPanel>
				<TabPanel value="3"><PasswordTab /></TabPanel>
				<TabPanel value="4"><Subscribe /></TabPanel>
			</TabContext>
		</Box>
	);
};

export default EditProfileNav;