import React from 'react';
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import './FryingButton.scss'

const FryingButton = ({
  label: label,
  onClick: onClick,
  style: style,
  btn_color: btn_color,
  disabled: disabled
}) => {

  const ButtonStyle = {
    padding: '20px 30px !important',
    height: '40px',
    backgroundColor: btn_color ? btn_color : '#F7941D',
    fontFamily: 'Karla',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '160%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    borderRadius: '40px',
    textTransform: 'none',
    border: `2px solid ${btn_color ? btn_color : '#F7941D'}`,
    '&:hover': {
      transition: "1s",
      background: "white",
      color: "#F7941D",
    },
    '&:disabled': {
      color: '#FFFFFF',
      background: '#F7941D',
      opacity: 0.4,
      boxShadow: '0px 3px 10px rgba(247, 148, 29, 0.3)',
    },
    style
  }
  const [loading, setLoading] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(label);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  const loadingClick = () => {
    setLoading(true)
    setButtonLabel('');
    setTimeout(() => {
      setLoading(false)
      setButtonLabel('Saved');
      setIsDisabled(true);
      setTimeout(() => {
        setButtonLabel('Save');
        setIsDisabled(false);
      }, 3000)
    }, 3000)
  }

  return (
    <Button
      className='button'
      onClick={() => {
        loadingClick();
        onClick()
      }}
      sx={ButtonStyle}
      disabled={isDisabled}
    >
      {loading && <div className='button--loading'></div>}
      {buttonLabel}
    </Button>
  );
};

export default FryingButton;