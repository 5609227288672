import React, {useEffect} from 'react';
import ProfileInput from "../default_components/ProfileInput";
import './Password.scss'
import FryingButton from "../../default_components/CustomButtons/FryingButton";
import {useState} from "react";
import Api from "../../../Api";
import DefaultSnackbar from "../../default_components/snackbar/SnackBar";
import {useMethodsSnackbar} from "../../default_components/snackbar/useSnackBarMethods";

const Password = ({setIsForgotPassword}) => {

  const [openModal, setOpenModal] = useState(false);
  const [btnIsDisable, setBtnIsDisable] = useState(true);
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordIsError, setNewPasswordIsError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [checkPasswordLength, setCheckPasswordLength] = useState();
  const [checkPasswordHaveNumbers, setCheckPasswordHaveNumbers] = useState();
  const [checkPasswordHaveLetters, setCheckPasswordHaveLetters] = useState();
  const { open, message, isSuccessful, close, getActiveSnackbar } = useMethodsSnackbar();

  useEffect(() => {
    if (newPassword === confirmPassword && newPassword.length > 0 && confirmPassword.length > 0 && currentPassword.length > 0) {
      setBtnIsDisable(false);
    } else setBtnIsDisable(true);
  }, [newPassword, confirmPassword, currentPassword]);

  useEffect(() => {
    Api.userProfile.getInfo().then(response => {
      setEmail(response.data.email)
    })
  }, [])

  const changePassword = (current_password, new_password) => {
    Api.Password(current_password, new_password).then(response => {
      console.log(response.data.status)
      if (response.data.status === 'Incorrect Password') {
        getActiveSnackbar('Incorrect password', false);
        setOpenModal(false);
        setCurrentPasswordError('');
        console.log('no')
      } else {
        getActiveSnackbar('Password was changed', true);
        setOpenModal(false);
        console.log('yes')
      }
    })
  }

  const newPasswordHandler = (e) => {
    setNewPassword(e.target.value);

    const str = /[0-9a-zA-Z!@#$%^&*]{8,}/;
    if (!str.test(String(e.target.value))) {
      setCheckPasswordLength(false);
    }
    else setCheckPasswordLength(true);

    const str1 = /[0-9]/;
    if (!str1.test(String(e.target.value))) {
      setCheckPasswordHaveNumbers(false);
    }
    else setCheckPasswordHaveNumbers(true);

    const str2 = /[a-zA-Z]/;
    if (!str2.test(String(e.target.value))) {
      setCheckPasswordHaveLetters(false);
    }
    else setCheckPasswordHaveLetters(true);
  };

  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
    if (newPassword === e.target.value) {
      setConfirmPasswordError('');
    } else setConfirmPasswordError('Password mismatch');
  }

  return (
    <div className='profile-password__container'>
      <div className='profile-password__current-password-container'>
        <ProfileInput
          onBlur={() => setCurrentPasswordError('')}
          error={currentPasswordError}
          onChange={(e) => setCurrentPassword(e.target.value)}
          value={currentPassword}
          showEye={true}
          label={'Current password'}
          type={'password'}
        />
        <p className='forgot-password' onClick={() => setIsForgotPassword(true)}>Forgot your password?</p>
      </div>

      <div className='profile-password__new-password-container'>
        <div style={{width: '100%', maxWidth: '380px'}}>
          <ProfileInput
            isError={newPasswordIsError}
            onChange={e => newPasswordHandler(e)}
            value={newPassword}
            showEye={true}
            label={'New password'}
            type={'password'}
          />
          <ul>
            <li style={checkPasswordLength === undefined ? {color: 'black'} : checkPasswordLength ? {color: 'green'} : {color: 'red'}}>minimum 8 characters</li>
            <li style={checkPasswordHaveNumbers === undefined ? {color: 'black'} : checkPasswordHaveNumbers ? {color: 'green'} : {color: 'red'}}>includes numbers</li>
            <li style={checkPasswordHaveLetters === undefined ? {color: 'black'} : checkPasswordHaveLetters ? {color: 'green'} : {color: 'red'}}>includes letters</li>
          </ul>
        </div>

        <div style={{width: '100%', maxWidth: '380px'}}>
          <ProfileInput
            onChange={(e) => confirmPasswordHandler(e)}
            value={confirmPassword}
            showEye={true}
            label={'Repeat new password'}
            type={'password'}
            error={confirmPasswordError}
          />
        </div>
      </div>

      <div className='profile-password__btn-container'>
        <FryingButton label={'Save'} disabled={btnIsDisable} onClick={() => changePassword(currentPassword, newPassword)}/>
      </div>

      <DefaultSnackbar
        open={open}
        onClose={close}
        message={message}
        isSuccessful={isSuccessful}
      />
    </div>
  );
};

export default Password;