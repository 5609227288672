import React, { useState } from "react";
import FPInputField from "../default_components/FPInputField";
import Api from "../../Api";
import FryingButton from "../default_components/CustomButtons/FryingButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import './MealEditingComponent.scss'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const blankArray = () => {
  return ["", "", "", "", "", "", "", "", "", ""];
};

const MealEditingComponent = ({ meal }) => {
  const [name, setName] = useState(meal.name);
  const [steps, setSteps] = useState(
    meal.steps.length === 0 ? blankArray() : meal.steps
  );
  const [editing, setEditing] = useState(false);
  const [personCount, setPersonCount] = useState(1);
  const persons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [duration, setDuration] = useState(1);
  const [hardnessOptions, setHardnessOptions] = useState(['easy', 'medium', 'hard']);
  const [hardness, setHardness] = useState('easy');

  const handleChangePersonCount = (event) => {
    setPersonCount(event.target.value);
  };

  const handleChangeDuration = (event) => {
    setDuration(event.target.value);
    if (event.target.value < 0) {
      setDuration(1);
    }
    if (event.target.value > 180) {
      setDuration(180);
    }
  }

  const leftArrowDurationChange = (event) => {
    setDuration(() => duration - 1);
    if (duration <= 0) {
      setDuration(1);
    }
  }

  const rightArrowDurationChange = (event) => {
    setDuration(Number(duration) + 1);
    if (duration > 180) {
        setDuration(180);
    }
  }

  const handleChangeHardness = (event) => {
    setHardness(event.target.value);
  }

  const StepsArray = () => {
    let array = [];

    for (let i = 0; i < 10; i++) {
      array.push(
        <FPInputField
          fieldName={`Step ${i + 1}`}
          onChange={(text) => {
            let oldSteps = Array.from(steps);
            oldSteps[i] = text;
            setSteps(oldSteps);
          }}
          type={"text"}
          fieldValue={steps[i]}
          width={"80%"}
        />
      );
    }

    return array;
  };

  const updateMeal = () => {
    Api.Meals.update(meal.id, {
      duration: duration,
      hardness: hardness,
      person: personCount,
      steps: steps,
      name: name,
    });
    setEditing(false);
  };

  return (
    <div>
      {meal.instructions}

      <FPInputField
        fieldName={"Name"}
        onChange={(text) => setName(text)}
        type={"text"}
        fieldValue={name}
        width={"80%"}
      />

      <div className='edit__form'>
        <div className='edit__input-container'>
          <label>Person count</label>
          <Select
            className='edit__select'
            value={personCount}
            onChange={handleChangePersonCount}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {persons.map((item, index) => {
              return (
                <MenuItem value={index + 1}>{item}</MenuItem>
              )
            })}
          </Select>
        </div>

        <div className='edit__input-container'>
          <label>Duration (min)</label>
          <div>
            <button disabled={duration === 1} onClick={leftArrowDurationChange} className='left__arrow'>
              <KeyboardArrowLeftIcon />
            </button>

            <input
              className='edit__input'
              type="number"
              min="1"
              max="180"
              value={duration === 0 ? 1 : duration}
              onChange={handleChangeDuration}
            />

            <button disabled={duration === 180} onClick={rightArrowDurationChange} className='right__arrow'>
              <KeyboardArrowRightIcon />
            </button>
          </div>
        </div>

        <div className='edit__input-container'>
          <label>Hardness</label>
          <Select
            className='edit__select'
            value={hardness}
            onChange={handleChangeHardness}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {hardnessOptions.map((item, index) => {
              return (
                <MenuItem value={item}>{item}</MenuItem>
              )
            })}
          </Select>
        </div>
      </div>

      {StepsArray()}

      <div className="row">
        <div className="col-md-6"></div>

        <div className="col-md-6"></div>

        <div className="col-md-6"></div>

        <div className="col-md-6"></div>

        <div className="col-md-6"></div>

        <div className="col-md-6"></div>

        <div className="col-md-6"></div>

        <div className="col-md-6"></div>

        <div className="col-md-6"></div>

        <div className="col-md-6"></div>

        <div style={{ marginTop: "30px", marginLeft: '15px' }}>
          <FryingButton
            label={"Save"}
            onClick={() => updateMeal()}
          />
        </div>
      </div>
    </div>
  );
};

export default MealEditingComponent;
