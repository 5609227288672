import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import {useState} from "react";
import MenuItem from "@mui/material/MenuItem";

const Dropdown = ({ anchorEl, dropdownOpen, setDropdownOpen }) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("FP-Auth-Token")

    navigate(`/login`, {
      replace: true,
    });
  }

  const goToProfile = () => {
    navigate(`/profile`, {
      replace: true,
    });
  }

  return (
    <Menu
      id="header-menu"
      anchorEl={anchorEl}
      keepMounted
      open={dropdownOpen}
      onClose={() => setDropdownOpen(false)}
    >
      <MenuItem
        onClick={goToProfile}
      >
        Profile
      </MenuItem>

      <MenuItem
        onClick={logout}
      >
        Logout
      </MenuItem>
    </Menu>
  )
}

export default Dropdown;
