import React from "react";
import {
  Navigate, Route, BrowserRouter,
  Routes,
} from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import MealOverview from "./components/recipe_description/MealOverview";
import NoMatch from "./components/default_components/NoMatch";
import Planner from "./components/planner/Planner";
import Profile from "./components/profile/Profile";
import MyRecipes from "./components/my_recipes/MyRecipes";
import ResetPassword from "./components/profile/Password/reset_password/ResetPassword";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter  basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="/planner"
            element={
              <PrivateRoute>
                <Planner />
              </PrivateRoute>
            }
          />

          <Route
            path="/recipe/:recipeId"
            element={
              <PrivateRoute>
                <MealOverview />
              </PrivateRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />

          <Route
            path="/my-recipes"
            element={
              <PrivateRoute>
                <MyRecipes />
              </PrivateRoute>
            }
          />

          <Route
            path="/register"
            element={
              <UnregisteredRoute>
                <Register />
              </UnregisteredRoute>
            }
          />

          <Route
            path="/login"
            element={
              <UnregisteredRoute>
                <Login />
              </UnregisteredRoute>
            }
          />

          <Route
            path="/recover_password"
            element={
              <UnregisteredRoute>
                <ResetPassword />
              </UnregisteredRoute>
            }
          />

          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

function PrivateRoute({ children, ...rest }) {
  if (!localStorage.getItem("FP-Auth-Token")) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function UnregisteredRoute({ children, ...rest }) {
  if (localStorage.getItem("FP-Auth-Token")) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
}

export default App;
