import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Api from "../../Api";
import { useParams } from "react-router-dom";
import "./meal_overview.scss";
import MealParameters from "./MealParameters";
import FPOrangeButton from "../default_components/FPOrangeButton";
import MealEditingComponent from "./MealEditingComponent";
import favorite_colored from "../dashboard/images/favorite_colored.svg";
import favorite_outlined from "../dashboard/images/favorite_outlined.svg";
import GetRandomRecipes from "./requests/GetRandomRecipes";
import SimilarRecipies from "./SimilarRecipies";
import ButtonComponent from "../default_components/CustomButtons/ButtonComponent";
import axios from "axios";

export default () => {
  const [meal, setMeal] = useState();
  const [likes, setLikes] = useState([]);
  const [editing, setEditing] = useState(false);
  const [role, setRole] = useState("");

  let { recipeId } = useParams();

  useEffect(() => {
    axios
      .get("users/profile", {
        params: {
          token: localStorage.getItem("FP-Auth-Token"),
        },
      })
      .then((response) => {
        setRole(response.data.role);
      });
  }, []);

  useEffect(() => {
    Api.Meals.get(recipeId).then((response) => {
      setMeal(response.data);

      Api.Likes.getLikes([response.data.id, response.data.id]).then(
        (response) => {
          setLikes(response.data);
        }
      );
    });
  }, []);

  const ReportClicked = (mealId) => {
    Api.Report(mealId).then((response) => {
      console.log(response.data);
    });
  };

  const addToFavorite = (mealId) => {
    Api.Likes.postLikes(mealId).then((response) => {
      Api.Likes.getLikes([mealId, mealId]).then((response) => {
        setLikes(response.data);
      });
    });
  };

  const removeFromFavorite = (mealId) => {
    Api.Likes.deleteLikes(mealId).then((response) => {
      Api.Likes.getLikes([mealId, mealId]).then((response) => {
        setLikes(response.data);
      });
    });
  };

  const mealIsFavorite = (mealId) => {
    return likes.filter((like) => like.meal_id === mealId)[0] !== undefined;
  };

  if (meal === undefined) {
    return (
      <>
        <Header />
        Loading...
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />

      <div className="recipe">
        <div className="recipe__container">
          <div>
            <div className="recipe__info">
              <div className="recipe__image">
                <img src={meal.image_url} />
              </div>

              <div className="recipe__description">
                <div className="recipe__meal-name">
                  {meal.name}
                  {mealIsFavorite(meal.id) ? (
                    <img
                      className="recipe__add-like-button"
                      onClick={(e) => {
                        removeFromFavorite(meal.id);
                      }}
                      src={favorite_colored}
                      alt=""
                    />
                  ) : (
                    <img
                      className="recipe__remove-like-button"
                      onClick={(e) => {
                        addToFavorite(meal.id);
                      }}
                      src={favorite_outlined}
                      alt=""
                    />
                  )}
                </div>

                <MealParameters meal={meal} />
                <ul style={{ paddingLeft: "17px" }}>
                  {meal.ingredients.map((ingredient, i) => {
                    return (
                      <li>
                        <div className="recipe__meal-ingridients">
                          {" "}
                          · {`${meal.measures[i]} ${ingredient}`}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="recipe__meal-instructions">
              <div className="recipe__meal-instructions-title">
                Instructions
              </div>
              {meal.steps.map((step, i) => {
                return (
                  <div className="recipe__meal-instructions-text">
                    <div className="recipe__meal-instructions-step-counter">
                      {i < 9 ? "0" + (i + 1) : i + 1}
                    </div>
                    <div className="recipe__meal-instructions-step-text">
                      {step}
                    </div>
                  </div>
                );
              })}

              {role === 'admin' && (<FPOrangeButton
                  text={"Edit recipe"}
                  onClick={() => setEditing(!editing)}
                  style={{ marginTop: "30px" }}
              />)
              }

              {role === 'admin' && editing && <MealEditingComponent meal={meal} />}

              {role === 'admin' && <ButtonComponent
                sx={{'&:hover': {transition: "1s", background: "red", color: "white"}, marginTop: "20px", border: "1px solid", transition: "1s", color: "white", fontFamily: "Karla", fontWeight: "700", textTransform: "none", fontSize: "17px", background: "#F7941D", width: "110px", height: "30px", paddingBottom: "20px !important", borderRadius: "40px"}}
                variant={"text"}
                fieldName={"Report"}
                onClick={ReportClicked}
              />}

            </div>
            <div>
              <div className="recipe__similar-recipies-text">
                Similar recipies
              </div>
              <div className="recipe__similar-recipies">
                <SimilarRecipies request={GetRandomRecipes} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
