export const dietSelectData = [
  {
    id: 0,
    name: "lactose free",
  },
  {
    id: 1,
    name: "gluten free",
  },
  {
    id: 2,
    name: "vegetarian",
  },
  {
    id: 3,
    name: "diabetes",
  },
  {
    id: 4,
    name:   "low carb"
  }
];

export const allergensSelectData = [
  {
    id: 0,
    name: 'milk',
  },
  {
    id: 1,
    name: 'mustard',
  },
  {
    id: 2,
    name: 'celery',
  },
  {
    id: 3,
    name: 'peanuts',
  },
  {
    id: 4,
    name: 'eggs',
  },
  {
    id: 5,
    name: 'wheat',
  },
  {
    id: 6,
    name: 'shellfish',
  },
  {
    id: 7,
    name: 'soybeans',
  },
  {
    id: 8,
    name: 'lupin'
  },
];