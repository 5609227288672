import React, { useEffect, useState } from "react";
import {Link, Navigate, Redirect, useNavigate} from "react-router-dom";
import Api from "../../Api";
import backgroundImg from './images/bg_reg_pages.jpg'
import {useMethodsSnackbar} from "../default_components/snackbar/useSnackBarMethods";
import DefaultSnackbar from "../default_components/snackbar/SnackBar";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailDirty, setEmailDirty] = useState(false)
  const [passwordDirty, setPasswordDirty] = useState(false)
  const [emailError, setEmailError] = useState("This field cannot be empty")
  const [passwordError, setPasswordError] = useState("This field cannot be empty")
  const [passwordErrorLater, setPasswordErrorLater] = useState("")
  const [passwordErrorNumber, setPasswordErrorNumber] = useState("")
  const [passwordVision, setPasswordVision] = useState(false)
  const [formValid, setFormValid] = useState(true)
  const [visionIconErrorEmail, setVisionIconErrorEmail] = useState(false)
  const [visionIconErrorPassLength, setVisionIconErrorPassLength] = useState(false)
  const [visionIconErrorPassIncludesLater, setVisionIconErrorPassIncludesLater] = useState(false)
  const [visionIconErrorPassIncludesNumber, setVisionIconErrorPassIncludesNumber] = useState(false)
  const [navigate, setNavigate] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const { open, message, isSuccessful, close, getActiveSnackbar } = useMethodsSnackbar();

  const redirect = useNavigate();

  useEffect(() => {
    if(emailError) {
      setFormValid(true)
    }
    else {
      setFormValid(false)
    }
  }, [emailError])

  const timeEmailVision = () => {
    setVisionIconErrorEmail(true)
    setEmailError("Correct email")
    setTimeout(() => {
      setVisionIconErrorEmail(false)
      setEmailError("")
    }, 1000 )
  }
  
  const timePassLengthVisionOk = () => {
    setVisionIconErrorPassLength(true)
    setTimeout(() => {
      setVisionIconErrorPassLength(false)
      setVisionIconErrorPassIncludesLater(false)
      setVisionIconErrorPassIncludesNumber(false)
      setPasswordError("")
    }, 1000 )
  }
  
  const timePassIncludesLaterOk = () => {
    setVisionIconErrorPassIncludesLater(true)
    setTimeout(() => {
      setVisionIconErrorPassIncludesLater(false)
      setPasswordErrorLater("")
    }, 1000 )
  }
  
  const PassIncludesNumberOk = () => {
    setVisionIconErrorPassIncludesNumber(true)
    setTimeout(() => {
      setVisionIconErrorPassIncludesNumber(false)
      setPasswordErrorNumber("")
    }, 1000 )
  }
  
  const emailHandler = (e) => {
    setEmail(e.target.value)
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(!re.test(String(e.target.value).toLowerCase())) {
      setEmailError("Incorrect email")
      setEmailDirty(true)
    }
    else {
      setEmailError("")
      setEmailDirty(false)
    }
    if(!e.target.value) {
      setEmailError("This field cannot be empty")
      setEmailDirty(true)
    }
  }
  
  const handlerVisionPass = () => {
    setPasswordVision(!passwordVision)
  }
  
  const blurHandler = (e) => {
    switch (e.target.name) {
      case "email":
        setEmailDirty(true)
        break
      case "password":
        setPasswordDirty(true)
        break
    }
  }
  
  const LoginClick = () => {
    // console.log(formValid)
    // if (!formValid) {
    //
    // }

    Api.Auth.login(email, password)
      .then((response) => {
        setNavigate(true);
      })
  }

  const sendRecoveryLink = () => {
    if (isForgetPassword) {
      Api.getRecoveryLink(email).then(response => {
        if (response.status === 201) {
          getActiveSnackbar('Link was send', true);
          setTimeout(() => {
            setIsForgetPassword(false)
          }, 2000)
        }
      })
    }
  }
  
  if (navigate) {
    return <Navigate to="/dashboard" />;
  }
  
  return (
    <div className="back-reg-page__box">
      <div className="back-reg-page__box-content">
        <div className='background__image' />
        <div className="FrPlan__white"/>

        { isForgetPassword ?
          <div className="RegisterComponent">
            <div className="JoinHeader">Forgot password? Reset it!</div>
            <p className='login__description'>Enter your email address and we'll send you a link to reset your password.</p>

            <div className="field-input">
              <p className="field-input__p"
                 style={emailDirty && emailError ? {color: "red"} : {color: "#4E598C"}}
              >
                Email
              </p>
              <input
                onChange={e => emailHandler(e)}
                onBlur={e => blurHandler(e)}
                className="field-input__box"
                name="email"
                type="text"
                value={email}
                placeholder="john_smith@mail.com"
                style={emailDirty && emailError ? {border: "1px solid red"} : {border: "1px solid #A3A8C3"}}
              />
              {(emailDirty && emailError) && <div style={{color: "red"}}>{emailError}</div>}
            </div>

            <p className='login__remember-pass'>
              Remember your password? <span onClick={() => setIsForgetPassword(false)} className='forgot-pass__link'>Log in</span>
            </p>

            <button
              style={{margin: '40px 0 20px 0'}}
              onClick={() => sendRecoveryLink()}
              disabled={formValid}
              className='registerButton'
            >
              <div>Send recovery link</div>
            </button>

            <DefaultSnackbar
              open={open}
              onClose={close}
              message={message}
              isSuccessful={isSuccessful}
            />
          </div>
          :
          <div className="RegisterComponent">
            <div className="JoinHeader">Log in</div>
            <div className="field-input">
              <p className="field-input__p">Email</p>
              <input
                onChange={e => emailHandler(e)}
                onBlur={e => blurHandler(e)}
                className="field-input__box"
                name="email"
                type="text"
                value={email}
                placeholder="john_smith@mail.com"
              />
            </div>
            {/*{(emailDirty && emailError) &&*/}
            {/*  <div*/}
            {/*    style={!visionIconErrorEmail ?*/}
            {/*      {display: "flex", alignItems: "center", color: "red"}*/}
            {/*      :*/}
            {/*      {display: "flex", alignItems: "center", color: "green"}}*/}
            {/*  >*/}
            {/*    {visionIconErrorEmail?*/}
            {/*      <div className="visionIconOk"/>*/}
            {/*      :*/}
            {/*      <div className="visionIconError"/>*/}
            {/*    }*/}
            {/*    {emailError}*/}
            {/*  </div>*/}
            {/*}*/}
            <div className="field-input">
              <p className="field-input__p">Password</p>
              <input
                onChange={e => setPassword(e.target.value)}
                onBlur={e => blurHandler(e)}
                className="field-input__box"
                name="password"
                type={passwordVision? "text" : "password"}
                value={password}
                placeholder=""
              />
              <div onClick={handlerVisionPass} className={passwordVision? "VisionPasswordButton" : "NoVisionPasswordButton"}></div>
            </div>
            {/*{(passwordDirty && passwordError) &&*/}
            {/*  <div*/}
            {/*    style={!visionIconErrorPassLength ?*/}
            {/*      {display: "flex", alignItems: "center", color: "red"}*/}
            {/*      :*/}
            {/*      {display: "flex", alignItems: "center", color: "green"}}*/}
            {/*  >*/}
            {/*    {visionIconErrorPassLength?*/}
            {/*      <div className="visionIconOk"/>*/}
            {/*      :*/}
            {/*      <div className="visionIconError"/>*/}
            {/*    }*/}
            {/*    {passwordError}*/}
            {/*  </div>*/}
            {/*}*/}
            {/*{(passwordDirty && passwordErrorLater) &&*/}
            {/*  <div*/}
            {/*    style={!visionIconErrorPassIncludesLater ?*/}
            {/*      {display: "flex", alignItems: "center", color: "red"}*/}
            {/*      :*/}
            {/*      {display: "flex", alignItems: "center", color: "green"}}*/}
            {/*  >*/}
            {/*    {visionIconErrorPassIncludesLater?*/}
            {/*      <div className="visionIconOk"/>*/}
            {/*      :*/}
            {/*      <div className="visionIconError"/>}*/}
            {/*    {passwordErrorLater}*/}
            {/*  </div>}*/}
            {/*{(passwordDirty && passwordErrorNumber) &&*/}
            {/*  <div*/}
            {/*    style={!visionIconErrorPassIncludesNumber ?*/}
            {/*      {display: "flex", alignItems: "center", color: "red"}*/}
            {/*      :*/}
            {/*      {display: "flex", alignItems: "center", color: "green"}}*/}
            {/*  >*/}
            {/*    {visionIconErrorPassIncludesNumber?*/}
            {/*      <div className="visionIconOk"/>*/}
            {/*      :*/}
            {/*      <div className="visionIconError"/>}*/}
            {/*    {passwordErrorNumber}*/}
            {/*  </div>*/}
            {/*}*/}
            <p onClick={() => setIsForgetPassword(true)} className="login__page_to_forgetPage">
              Forgot your password?
            </p>
            <div onClick={LoginClick} className={"registerButton"}>
              <div>Log in</div>
            </div>
            {/*<div className="signUpWitchGoogle">*/}
            {/*  <p>Log in with Google</p>*/}
            {/*  <div className="iconGoogle"/>*/}
            {/*</div>*/}
            <div className="JoinNowTextLine">
              <p>Don’t have account?</p>
              <Link to="/register" className="register__input-side__login-link">
                Sign up
              </Link>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Login;
