import React, {useEffect, useState} from 'react';
import "./email.scss"
import ProfileInput from "../default_components/ProfileInput";
import Api from "../../../Api";
import ConfirmationModal from "../default_components/confirmation_modal/ConfirmationModal";
import FryingButton from "../../default_components/CustomButtons/FryingButton";
import { useMethodsSnackbar } from "../../default_components/snackbar/useSnackBarMethods";
import DefaultSnackbar from "../../default_components/snackbar/SnackBar";

const Email = () => {

	const [email, setEmail] = useState('');
	const [newEmail, setNewEmail] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const [emailError, setEmailError] = useState('');
	const [code, setCode] = useState('');
	const [isDisabledButton, setIsDisabledButton] = useState(true);
	const [codeError, setCodeError] = useState('');

	const { open, close, message, isSuccessful, getActiveSnackbar } = useMethodsSnackbar();

	useEffect(() => {
		Api.userProfile.getInfo().then(response => {
			setEmail(response.data.email);
			console.log(response.data)
		})
	}, [])

	const sendCodeConfirmation = (new_email) => {
		setOpenModal(true)
		setIsDisabledButton(true);
		Api.EmailSwitchCode(new_email).then(response => console.log(response.data))
	}

	const emailVerification = (e) => {
		setNewEmail(e.target.value)
		const str = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
		if (!str.test(String(e.target.value))) {
			setEmailError('Email is not valid!');
			setIsDisabledButton(true);
		} else {
			setEmailError('');
			setIsDisabledButton(false);
		}
	}

	const submitEmailChange = (new_email, code) => {
		Api.NewEmail(new_email, code).then(response => {
			console.log(response)
			if (response.data.status === 'ok') {
				setEmail(new_email)
				setCode('');
				setOpenModal(false);
				getActiveSnackbar('Email changed successfully', true);
				setIsDisabledButton(true);
				setNewEmail('');
			} else {
				setCodeError('incorrect code');
			}
		})
	}

	const codeHandler = (e) => {
		setCodeError('');
		setCode(e.target.value);
		if (e.target.value.length === 6) {
			setIsDisabledButton(false);
		} else {
			setIsDisabledButton(true);
		}
	}
	
	return (
		<div className='profile-email__container'>
			<div className='profile-email__current-container'>
				<p>Current Email</p>
				<p>{email}</p>
			</div>

			<ProfileInput value={newEmail} error={emailError} onChange={e => emailVerification(e)} label={'New Email'} type={'text'}/>

			<div className='profile-email-btn-container'>
				<FryingButton label={'Save'} disabled={isDisabledButton} onClick={() => {
					sendCodeConfirmation(newEmail)
				}} />
			</div>

			<ConfirmationModal label={'Email Confirmation'} active={openModal} setActive={setOpenModal}>
				<div className='email-modal__text-container'>
					<p>We've send you an email on <p className='new-email-text'>{email}</p>. </p>
					<p>Please enter the code from your email.</p>
				</div>

				<ProfileInput error={codeError} maxLength={6} style={{width: '170px'}} label={'Code'} type={'code'} onChange={(e => codeHandler(e))}/>

				<div className='email-modal__btn-container'>
					<FryingButton label={'Confirm'} disabled={isDisabledButton} onClick={() => submitEmailChange(newEmail, code)}/>
				</div>
			</ConfirmationModal>

			<DefaultSnackbar
				open={open}
				onClose={close}
				message={message}
				isSuccessful={isSuccessful}
			/>
		</div>
	);
};

export default Email;