import React, {useEffect, useState} from 'react';
import ProfileInput from "../../default_components/ProfileInput";
import './ForgotPassword.scss'
import Api from "../../../../Api";
import {useNavigate} from "react-router-dom";

const ForgotPassword = ({setIsForgotPassword}) => {

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState("This field cannot be empty");
  const [emailDirty, setEmailDirty] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if(emailError) {
      setIsButtonDisabled(true)
    }
    else {
      setIsButtonDisabled(false)
    }
  }, [emailError])

  const emailHandler = (e) => {
    setEmail(e.target.value)
    if(!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(e.target.value)) {
      setEmailError("Incorrect email")
      setEmailDirty(true)
    }
    else {
      setEmailError("")
      setEmailDirty(false)
    }
    if(!e.target.value) {
      setEmailError("This field cannot be empty")
      setEmailDirty(true)
    }
  }

  const sendRecoveryLink = () => {
    const myToken = '22bf6635abe02eaf8110c36e'
    Api.getRecoveryLink(email).then(response => {
      if (response.status === 201) {
        navigate(`/recover_password?password_token={${myToken}}`)
      }
    })
  }

  return (
    <div className='forgot-pass__container'>
      <div>
        <h1 className='forgot-pass__title'>Forgot password? Reset it!</h1>
        <p className='forgot-pass__description'>Enter your email address and we'll send you a link to reset your password.</p>
        <ProfileInput
          type={'email'}
          label={'Email'}
          value={email}
          placeholder={'Enter your email'}
          onChange={(e) => emailHandler(e)}
          error={emailDirty && emailError}
        />

        <p className='forgot-pass__remember-pass'>
          Remember your password? <span onClick={() => setIsForgotPassword(false)} className='forgot-pass__link'>Log in</span>
        </p>

        <button
          onClick={() => sendRecoveryLink()}
          disabled={isButtonDisabled}
          className='forgot-pass__button'
        >
          <div>Send recovery link</div>
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;