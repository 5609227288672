import React, {useCallback, useEffect, useRef, useState} from "react";
import DayPlan from "./DayPlan";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import WeekBeginning from "../../lib/date/WeekBeginning";
import Api from "../../Api";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateToString from "../../lib/date/DateToString";
import "./PlannerUpd.scss";
import {DateTime} from "luxon";

const dayPart = ["Breakfast", "Lunch", "Dinner", "Dessert"];

const Planner = () => {
  const dayBlocks = [];

  const breakfastRef = useRef();
  const lunchRef = useRef();
  const dinnerRef = useRef();
  const desertRef = useRef();

  const [currentDay, setCurrentDay] = useState(0);
  const [weeklyData, setWeeklyData] = useState([]);
  const [date, setDate] = useState(DateTime.now().startOf("week"));
  const [data, setData] = useState([]);
  const [showBreakfast, setShowBreakfast] = useState(false);
  const [showLunch, setShowLunch] = useState(false);
  const [showDinner, setShowDinner] = useState(false);
  const [showDesert, setShowDesert] = useState(false);
  const [showSetting ,setShowSetting] = useState(false)
  const [colories, setColories] = useState(100);

  const handleInputChange = useCallback((e) => {
    setColories(e.target.value)
  }, [setColories])
  
  useEffect(() => {
    Api.Schedules.get([
      date.toISODate(),
      date.plus({ day: 1 }).toISODate(),
      date.plus({ day: 2 }).toISODate(),
      date.plus({ day: 3 }).toISODate(),
      date.plus({ day: 4 }).toISODate(),
      date.plus({ day: 5 }).toISODate(),
      date.plus({ day: 6 }).toISODate(),
    ]).then((response) => {
      setData(response.data);
    });
  }, [date]);
  
  for (let i = 0; i < 7; i++) {
    dayBlocks.push(
      <DayPlan
        data={weeklyData[i]}
        selected={currentDay === i}
        date={date.plus({ day: i })}
        setCurrentDay={setCurrentDay}
        index={i}
      />
    );
  }
 
  const clickShowBreakfast = () => {
    setShowBreakfast(!showBreakfast)
  }
  const clickShowLunch = () => {
    setShowLunch(!showLunch)
  }
  const clickShowDinner = () => {
    setShowDinner(!showDinner)
  }
  const clickShowDesert = () => {
    setShowDesert(!showDesert)
  }
  
  const ShowSettingsPlanner = () => {
    setShowSetting(!showSetting)
    setSelectParam(false)
  }

  const [checkValueAll, setCheckValueAll] = useState(true)
  const [checkValueEng, setCheckValueEng] = useState(false)
  const [checkValueDeu, setCheckValueDeu] = useState(false)
  const [masNumbAll, setMasNumbAll] = useState("All ")
  const [masNumbEng, setMasNumbEng] = useState("")
  const [masNumbDeu, setMasNumbDeu] = useState("")
  const [selectParam, setSelectParam] = useState(false)
  
  const  masNumb = [masNumbAll, masNumbEng, masNumbDeu]
  
  return (
    <>
      <Header/>
      <div className="planner-container__settings">
        {/*<div*/}
        {/*  onClick={ShowSettingsPlanner}*/}
        {/*  className={showSetting? "planner-settings__on" : "planner-settings__off"}*/}
        {/*>*/}
        {/*  It's ICON Setting? What?*/}
        {/*</div>*/}
        <div className={showSetting? "planner-con" : "planner-con__no-vision"}>
          <div className="planner-con__max_calories-input">
            <p>Max calories:
              <input
                type={"text"}
                value={colories}
                onChange={handleInputChange}
                maxLength= "3"
                className="planner-con__max-calories"
              />
            </p>
            <div className={"max_calories-input__arrows"}>
              <div onClick={() => {setColories(colories + 50)}}>
                <div  className="container-toggle__arrow"/>
              </div>
              <div>
                <div
                  onClick={() => {
                    setColories(colories - 50)
                    if (colories < 0) setColories(0)
                  }}
                  className="container-toggle__arrow a-s-down"/>
              </div>
            </div>
          </div>
          <div style={{display: "flex"}}>
            <div
              onClick={() => {setSelectParam(!selectParam)}}
              className="planner-con__Cuisine">
              <p>Change cuisine&nbsp;:  &nbsp;</p>
            </div>
            <div className="select-parameters__Cuisine-box">
              <div className="select-parameters__show">{masNumb}</div>
              <div className={selectParam ? "select-parameters__drop" : "select-parameters__drop_not-vision"}>
                <div>
                  All
                  <div
                    onClick={() => {
                      setCheckValueAll(!checkValueAll)
                      checkValueAll? setMasNumbAll("") : setMasNumbAll("All ")
                      if(masNumbAll !== ""){
                        //запрос
                      }
                    }}
                    className="like-box"
                  />
                </div>
                <div>
                  Eng
                  <div
                    onClick={() => {
                      setCheckValueEng(!checkValueEng)
                      checkValueEng? setMasNumbEng("") : setMasNumbEng("Eng ")
                      if(masNumbEng !== ""){
                        //запрос
                      }
                    }}
                    className="like-box"
                  />
                </div>
                <div>
                  Deu
                  <div
                    onClick={() => {
                      setCheckValueDeu(!checkValueDeu)
                      checkValueDeu? setMasNumbDeu("") : setMasNumbDeu("Deu")
                      if(masNumbDeu !== ""){
                        //запрос
                      }
                    }}
                    className="like-box"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="planner">
        <div className="planner-container">
          <div className="planner__calendar-row"
               onClick={() => {
                 setShowBreakfast(false)
                 setShowLunch(false)
                 setShowDinner(false)
                 setShowDesert(false)
               }}>
            <ArrowBackIosIcon
              onClick={() => {
                setDate(date.minus({ day: 7 }));
                setCurrentDay(0);
              }}
              className="arrow"
            />
            {dayBlocks}
            <ArrowForwardIosIcon
              onClick={() => {
                setDate(date.plus({ day: 7 }));
                setCurrentDay(0);
              }}
              className="arrow"
            />
          </div>
          <div className="planner__mealtime">
            {/*/111 breakfast------------------------------------/*/}
            <div className="planner__mealtime-row">
              <div onClick={clickShowBreakfast} className="planner__mealtime-row-info">
                <div className="planner__mealtime-row-time">
                  <ArrowForwardIosIcon className={`icon-arrow ${showBreakfast && ' active'}`} />
                  <p>{dayPart[0]}</p>
                </div>
                <div className="planner__mealtime-row-mealname">
                  <p>{data[currentDay]?.breakfast.name}</p>
                </div>
                <div className="planner__mealtime-row-ccal">
                  {/*<p>{data[currentDay]?.breakfast.id} Ccal</p>*/}
                </div>
              </div>
              {/*{showBreakfast ?*/}
              <div
                style={!showBreakfast ? {height: '0px'} : {height: breakfastRef.current?.scrollHeight, transition: 'height ease .5s'}}
                ref={breakfastRef}
                className='parent-container'
              >
                <div className='content'>
                  <div className="planner__mealtime-row-hidden-image">
                    <div className="planner__mealtime-row-hidden-text">
                      <img src={data[currentDay]?.breakfast.image_url}/>
                    </div>
                  </div>
                  <div>
                    <p>Ingredients</p>
                    <div className="">
                      <ul style={{paddingLeft: "17px"}}>
                        {data[currentDay]?.breakfast.ingredients.map((ingredient, i) => {
                          return (
                            <li>
                              <div className="">
                                {" "}
                                · {`${data[currentDay]?.breakfast.measures[i]} ${ingredient}`}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
                {/*:*/}
                {/*<div></div>*/}
              {/*}*/}
            </div>
            {/*/111 LUNCH------------------------------------/*/}

            <div className="planner__mealtime-row">
              <div onClick={clickShowLunch} className="planner__mealtime-row-info">
                <div className="planner__mealtime-row-time">
                  <ArrowForwardIosIcon className={`icon-arrow ${showLunch && ' active'}`} />
                  <p>{dayPart[1]}</p>
                </div>
                <div className="planner__mealtime-row-mealname">
                  <p>{data[currentDay]?.lunch.name}</p>
                </div>
                <div className="planner__mealtime-row-ccal">
                  {/*<p>{data[currentDay]?.breakfast.id} Ccal</p>*/}
                </div>
              </div>
              {/*{showBreakfast ?*/}
              <div
                style={!showLunch ? {height: '0px'} : {height: lunchRef.current?.scrollHeight, transition: 'height ease .5s'}}
                ref={lunchRef}
                className='parent-container'
              >
                <div className='content'>
                  <div className="planner__mealtime-row-hidden-image">
                    <div className="planner__mealtime-row-hidden-text">
                      <img src={data[currentDay]?.lunch.image_url}/>
                    </div>
                  </div>
                  <div>
                    <p>Ingredients</p>
                    <div className="">
                      <ul style={{paddingLeft: "17px"}}>
                        {data[currentDay]?.lunch.ingredients.map((ingredient, i) => {
                          return (
                            <li>
                              <div className="">
                                {" "}
                                · {`${data[currentDay]?.lunch.measures[i]} ${ingredient}`}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*:*/}
              {/*<div></div>*/}
              {/*}*/}
            </div>

            <div className="planner__mealtime-row">
              <div onClick={clickShowDinner} className="planner__mealtime-row-info">
                <div className="planner__mealtime-row-time">
                  <ArrowForwardIosIcon className={`icon-arrow ${showDinner && ' active'}`} />
                  <p>{dayPart[2]}</p>
                </div>
                <div className="planner__mealtime-row-mealname">
                  <p>{data[currentDay]?.dinner.name}</p>
                </div>
                <div className="planner__mealtime-row-ccal">
                  {/*<p>{data[currentDay]?.breakfast.id} Ccal</p>*/}
                </div>
              </div>
              {/*{showBreakfast ?*/}
              <div
                style={!showDinner ? {height: '0px'} : {height: dinnerRef.current?.scrollHeight, transition: 'height ease .5s'}}
                ref={dinnerRef}
                className='parent-container'
              >
                <div className='content'>
                  <div className="planner__mealtime-row-hidden-image">
                    <div className="planner__mealtime-row-hidden-text">
                      <img src={data[currentDay]?.dinner.image_url}/>
                    </div>
                  </div>
                  <div>
                    <p>Ingredients</p>
                    <div className="">
                      <ul style={{paddingLeft: "17px"}}>
                        {data[currentDay]?.dinner.ingredients.map((ingredient, i) => {
                          return (
                            <li>
                              <div className="">
                                {" "}
                                · {`${data[currentDay]?.dinner.measures[i]} ${ingredient}`}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*:*/}
              {/*<div></div>*/}
              {/*}*/}
            </div>

            <div className="planner__mealtime-row">
              <div onClick={clickShowDesert} className="planner__mealtime-row-info">
                <div className="planner__mealtime-row-time">
                  <ArrowForwardIosIcon className={`icon-arrow ${showDesert && ' active'}`} />
                  <p>{dayPart[3]}</p>
                </div>
                <div className="planner__mealtime-row-mealname">
                  <p>{data[currentDay]?.desert.name}</p>
                </div>
                <div className="planner__mealtime-row-ccal">
                  {/*<p>{data[currentDay]?.breakfast.id} Ccal</p>*/}
                </div>
              </div>
              {/*{showBreakfast ?*/}
              <div
                style={!showDesert ? {height: '0px'} : {height: desertRef.current?.scrollHeight, transition: 'height ease .5s'}}
                ref={desertRef}
                className='parent-container'
              >
                <div className='content'>
                  <div className="planner__mealtime-row-hidden-image">
                    <div className="">
                      <img src={data[currentDay]?.desert.image_url}/>
                    </div>
                  </div>
                  <div>
                    <p>Ingredients</p>
                    <div className="planner__mealtime-row-hidden-text">
                      <ul style={{paddingLeft: "17px"}}>
                        {data[currentDay]?.desert.ingredients.map((ingredient, i) => {
                          return (
                            <li>
                              <div className="">
                                {" "}
                                · {`${data[currentDay]?.desert.measures[i]} ${ingredient}`}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*:*/}
              {/*<div></div>*/}
              {/*}*/}
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
  export default Planner;
