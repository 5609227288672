import React from "react";
import styled from "styled-components";

const DayComponent = styled.div`
  cursor: pointer;
`;

const CalendarBlock = styled.div`
  background-color: ${(props) => (props.selected ? "white" : "#F7941D")};
  border: 3px solid ${(props) => (props.selected ? "#F7941D" : "#F7941D")};
  width: 140px;
  height: 140px;
  margin-right: 20px;
  border-radius: 30px;
  box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.15);

  p {
    font-weight: ${(props) => (props.selected ? "600" : "400")};
    font-size: 24px;
    line-height: 120%;
    align-self: stretch;
    text-align: center;
    padding-top: 8px;
    color: ${(props) => (props.selected ? "#F7941D" : "white")};
    margin-top: 44px;
  }
`;

const weekDayNames = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
const dayPart = ["breakfast", "lunch", "dinner"];

const DayPlan = ({ index, selected, setCurrentDay, date, CloseTask}) => {
  return (
    <DayComponent className="week-day" onClick={() => {
      setCurrentDay(index)
      CloseTask();
    }}>
      <p className="week-day__date">
        {date.day} / {date.month}
      </p>
      <CalendarBlock selected={selected}>
        <p>{weekDayNames[index]}</p>
      </CalendarBlock>
    </DayComponent>
  );
};

export default DayPlan;
