import React from 'react';
import './ConfirmationModal.scss'
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const ConfirmationModal = ({
  label: label,
  active,
  setActive,
  children,
}) => {

  return (
    <div
      className={!active ? 'confirmation-modal__container': 'confirmation-modal__container off'}
      onClick={() => setActive(false)}
    >
      <div
        className='confirmation-modal__content'
        onClick={e => e.stopPropagation()}
      >
        <div className='confirmation-modal__header-container'>
          <h1>{label}</h1>
          <CloseOutlinedIcon onClick={() => setActive(false)} className='icon'/>
        </div>

        {children}
      </div>
    </div>
  );
};

export default ConfirmationModal;