import React from 'react';
import {Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";

const DefaultSnackbar = ({ open, close, message, isSuccessful }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={ open }
      autoHideDuration={ 1500 }
      onClose={ close }
    >
      <div>
        { !isSuccessful ?
          <Alert onClose={ close } severity="error">
            { message }
          </Alert>
         :
          <Alert onClose={ close } severity="success">
            { message }
          </Alert>
        }
      </div>
    </Snackbar>
  );
};

export default DefaultSnackbar;